import React, { useState, useEffect } from "react";
import leftArrow from "../../assets/leftArrow.png";
import superonLogo from "../../assets/superon.png";
import notificationLogo from "../../assets/notification-icon.png";
import loadingGif from "../../assets/loading.gif";
import submitLogo from "../../assets/icon-submit.svg";
import cancelLogo from "../../assets/icon-cancel.svg";
import calenderIcon from "../../assets/calenderIcon.png";
import resetLogo from "../../assets/reset.svg";

import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./date.css";

// import {
//   CircularProgressbarWithChildren,
//   buildStyles,
// } from "react-circular-progressbar";
// import "./table.css";
// import Footer from "../Footer";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../Components/Utils/config";
import axios from "axios";
import Navbar from "../Navbar";

function Report() {
  const navigate = useNavigate();

  const params = useParams();

  const phoneNum = localStorage.getItem("superonUserPhNum");

  const [dataList, setDataList] = useState([]);
  const [quarter, setCurrentQuarter] = useState(0);
  const [showLoading, setShowLoading] = useState(false);

  const [searchDetails, setSearchDetails] = useState({
    dealerName: "",
    dateFrom: "",
    dateTo: "",
  });

  function getCurrentMonth() {
    const currentDate = new Date();
    const monthIndex = currentDate.getMonth();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const year = currentDate.getFullYear();
    const nextMonth = monthIndex + 1;
    const firstDayOfNextMonth = new Date(year, nextMonth, 1);
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const daysLeftInMonth = Math.floor(
      (firstDayOfNextMonth - currentDate) / millisecondsPerDay
    );
    return {
      monthName: monthNames[monthIndex],
      monthIndex: monthIndex,
      currentYear: year,
      daysLeftInMonth,
    };
  }

  const { monthName, monthIndex, currentYear, daysLeftInMonth } =
    getCurrentMonth();
  console.log(monthName); // Output: "April" (or the current month name)
  console.log(monthIndex + 1); // Output: 3 (or the current month index, since January is 0)
  console.log(currentYear);

  const [userTargetData, setUserTargetData] = useState({});
  const [percentage, setPercentage] = useState(0);

  // get Current Quarter
  function getCurrentQuarterAndDays() {
    const currentMonth = new Date().getMonth();

    const quarters = {
      1: [3, 4, 5], // Quarter 1: April, May, June
      2: [6, 7, 8], // Quarter 2: July, August, September
      3: [9, 10, 11], // Quarter 3: October, November, December
      4: [0, 1, 2], // Quarter 4: January, February, March
    };

    // get the current quarter based on the current month
    let currentQuarter;
    for (const quarter in quarters) {
      if (quarters[quarter].includes(currentMonth)) {
        currentQuarter = quarter;
        break;
      }
    }

    // calculate the number of days left in the current quarter
    const currentQuarterMonths = quarters[currentQuarter];
    const currentQuarterEndMonth =
      currentQuarterMonths[currentQuarterMonths.length - 1];
    const currentQuarterEndDate = new Date(
      new Date().getFullYear(),
      currentQuarterEndMonth + 1,
      0
    );
    const daysLeftInQuarter = Math.ceil(
      (currentQuarterEndDate - new Date()) / (1000 * 60 * 60 * 24)
    );

    setCurrentQuarter(currentQuarter);

    return { currentQuarter, daysLeftInQuarter };
  }

  const getUserTargetData = async () => {
    let data = {
      spid: phoneNum,
      month: params.quarter,
      year: params.year + "",
    };
    let res = await axios.post(config.ipAddress + `/target`, data);

    console.log(res?.data?.data);
    let allValues = res?.data?.data;
    let sortedValues = allValues.sort(function (a, b) {
      // To maintain the original order
      return 0;
    });
    console.log(sortedValues);
    setUserTargetData(sortedValues);
    let value = res?.data?.data;
    console.log(value.achievedtarget);
    let percentage = (value.achievedtarget / value.totaltarget) * 100;

    setPercentage(percentage);
  };

  // table data
  const getInvoiceDetails = async () => {
    setShowLoading(true);

    // let data = {
    //   spid: phoneNum,
    //   month: quarter + "",
    //   year: new Date().getFullYear() + "",
    // };

    setSearchDetails({
      dealerName: "",
      dateFrom: "",
      dateTo: "",
    });

    let data = {
      spid: phoneNum,
      month: params.quarter,
      year: params.year + "",
    };

    try {
      let response = await axios.post(
        config.ipAddress + `/invoice/${data.spid}`,
        data
      );
      console.log(response.data.data);
      let allData = response?.data?.data;

      console.log(allData.length);
      allData.sort((a, b) => {
        const dateA = new Date(
          a.DocDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
        );
        const dateB = new Date(
          b.DocDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
        );

        if (dateA > dateB) {
          return -1;
        } else if (dateA < dateB) {
          return 1;
        } else {
          return 0;
        }
      });
      setDataList(allData);
      setShowLoading(false);
    } catch (error) {
      console.log(error);
      setShowLoading(false);
      setDataList([]);
    }
  };

  // Search functionality
  const getSearchData = async () => {
    console.log(searchDetails);
    if (!searchDetails.dealerName) return alert("Please enter dealer name!");
    let dateForm = convertDateString(searchDetails.dateFrom);
    let dateTo = convertDateString(searchDetails.dateTo);

    let data = {
      spid: phoneNum,
      month: params.quarter,
      year: params.year + "",
      dealer: searchDetails.dealerName,
      fromdate: dateForm || "",
      todate: dateTo || "",
    };

    console.log(data);

    try {
      let response = await axios.post(
        config.ipAddress + `/invoicesearch`,
        data
      );
      console.log(response.data.data);
      let allData = response?.data?.data;

      console.log(allData.length);
      allData.sort((a, b) => {
        const dateA = new Date(
          a.DocDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
        );
        const dateB = new Date(
          b.DocDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
        );

        if (dateA > dateB) {
          return -1;
        } else if (dateA < dateB) {
          return 1;
        } else {
          return 0;
        }
      });
      setDataList(allData);
      setShowLoading(false);
    } catch (error) {
      console.log(error);
      setShowLoading(false);
      setDataList([]);
    }

    // console.log(dateForm, dateTo);

    // setDataList(allData);
    //   setShowLoading(false);
  };

  function formatDate(inputDate) {
    // Split the input date string into its components
    const [day, month, year] = inputDate.split(/[-\s:]+/);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const abbreviatedMonth = monthNames[parseInt(month) - 1];

    const outputDate = `${day}-${abbreviatedMonth}-${year}`;

    return outputDate;
  }

  // convert Number with Comma
  const numberWithCommas = (numberStr) => {
    let num = Number(numberStr);
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const convertDateString = (dateStr) => {
    if (!dateStr) return;
    const originalDate = new Date(dateStr);
    const day = originalDate.getDate().toString().padStart(2, "0");
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
    const year = originalDate.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  useEffect(() => {
    // getUserTargetData();
    getInvoiceDetails();
    getCurrentQuarterAndDays();
  }, []);

  if (showLoading)
    return (
      <div className="h-screen flex flex-col justify-center items-center gap-5">
        <h1 className="font-semibold text-lg">Please wait, Loading...</h1>
        <img src={loadingGif} alt="loading-gif" className="  w-10" />
      </div>
    );
  return (
    <div className="">
      {/* navbar */}
      <div className="sticky w-full top-0 left-0 z-10">
        <Navbar />
      </div>

      <section className=" min-h-[89.2%] overflow-auto px-[13px] py-[16px] bg-[#fafafa]">
        {/* second container */}

        <div className="shadow-6xl rounded-t-[10px] h-full overflow-auto">
          {/* <h1 className="font-semibold text-[18px] ">Current Target Report</h1> */}
          <div className="flex gap-2  pt-4 pl-4">
            <div className="bg-[#FF971A] w-[2px] rounded-full"></div>
            <div className="">
              <h1 className="text-lg ">
                Target Report -{" "}
                <span className="">
                  Q{params.quarter},{" "}
                  {params.year === undefined
                    ? params.year
                    : new Date().getFullYear()}
                </span>
              </h1>
            </div>
          </div>
          {/* search bar */}
          <section className="px-4 pt-[13px] ">
            <div>
              <input
                type="text"
                placeholder="Dealer Name"
                className="w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light"
                value={searchDetails.dealerName}
                onChange={(e) =>
                  setSearchDetails({
                    ...searchDetails,
                    dealerName: e.target.value,
                  })
                }
              />
              <div className=" flex justify-between items-center gap-2  mt-[10px]">
                <Datepicker
                  // portalId="root-portal"
                  popperClassName="some-custom-class1"
                  selected={searchDetails.dateFrom}
                  onChange={(date) =>
                    setSearchDetails({ ...searchDetails, dateFrom: date })
                  }
                  // popperPlacement="top-end"
                  // popperModifiers={[
                  //   {
                  //     name: "offset",
                  //     options: {
                  //       offset: [5, 10],
                  //     },
                  //   },
                  //   {
                  //     name: "preventOverflow",
                  //     options: {
                  //       rootBoundary: "viewport",
                  //       tether: false,
                  //       altAxis: true,
                  //     },
                  //   },
                  // ]}
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: { behavior: ["bottom"] },
                    preventOverflow: { enabled: true },
                    hide: { enabled: false },
                  }}
                  dateFormat={"yyyy/MM/dd"}
                  placeholderText="From"
                  customInput={
                    <input
                      value={searchDetails.dateFrom}
                      className="example-custom-input"
                      // onClick={onClick}
                      onChange={(e) =>
                        setSearchDetails({
                          ...searchDetails,
                          dateFrom: e.target.value,
                        })
                      }
                      // ref={ref}
                    ></input>
                  }
                  // showYearDropdown
                  wrapperClassName="datePicker"
                ></Datepicker>
                {/* <img src={calenderIcon} alt="calender-logo" /> */}
                {/* <span>To</span> */}
                <Datepicker
                  portalId="root-portal"
                  popperClassName="some-custom-class2"
                  // popperPlacement="top-end"
                  // popperModifiers={[
                  //   {
                  //     name: "offset",
                  //     options: {
                  //       offset: [5, 10],
                  //     },
                  //   },
                  //   {
                  //     name: "preventOverflow",
                  //     options: {
                  //       rootBoundary: "viewport",
                  //       tether: false,
                  //       altAxis: true,
                  //     },
                  //   },
                  // ]}
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: { behavior: ["bottom"] },
                    preventOverflow: { enabled: true },
                    hide: { enabled: false },
                  }}
                  selected={searchDetails.dateTo}
                  onChange={(date) =>
                    setSearchDetails({ ...searchDetails, dateTo: date })
                  }
                  customInput={
                    <input
                      value={searchDetails.dateTo}
                      className="example-custom-input"
                      // onClick={onClick}
                      // onChange={(e) =>
                      //   setSearchDetails({
                      //     ...searchDetails,
                      //     dateTo: e.target.value,
                      //   })
                      // }
                      // ref={ref}
                    ></input>
                  }
                  dateFormat={"yyyy/MM/dd"}
                  placeholderText="To"
                  // showYearDropdown
                  wrapperClassName="datePicker"
                ></Datepicker>
                <div className="flex justify-end items-center gap-2">
                  <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
                    <img
                      src={resetLogo}
                      alt="submit-logo"
                      className="w-4 "
                      onClick={() => getInvoiceDetails()}
                    />
                  </div>
                  <div className="border-[1px] flex justify-end  border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
                    <img
                      src={submitLogo}
                      alt="submit-logo"
                      className="w-[18px] mx-auto "
                      onClick={() => getSearchData()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="mt-[13px]  overflow-x-auto  shadow-lg">
            <table className="w-full text-sm   ">
              <thead className="text-xs text-black   bg-gray-300">
                <tr>
                  <th scope="col" className="px-2 py-3  text-center">
                    Inv. No.
                  </th>
                  <th scope="col" className="px-2 py-3  text-center">
                    Inv. Date
                  </th>

                  {/* <th scope="col" className="px-6 py-3">
                  CODE
                </th> */}
                  <th scope="col" className="px-2 py-3  text-center">
                    Dealer
                  </th>
                  <th scope="col" className="px-2 py-3  text-center">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataList.length
                  ? dataList.map((list, index) => {
                      return (
                        <tr
                          key={index}
                          className="bg-white border-b border-gray-100 "
                        >
                          <td
                            className="px-2 py-2   text-center"
                            // onClick={() => navigate(`/invoice/${list.DocNum}`)}
                          >
                            {/* {numberWithCommas(list.DocNum)} */}
                            {list.DocNum}
                          </td>
                          <td className="px-2 py-2 w-[88px] text-[12px] text-center">
                            {formatDate(list.DocDate)}
                            {/* {list.DocDate} */}
                          </td>
                          <td className="px-2 py-2 w-[110px] text-[12px] text-center">
                            {list.CardName}
                          </td>

                          <td className="px-2 py-2   text-center">
                            ₹ {numberWithCommas(list.DocTotalSy)}
                            {/* {list.DocTotalSy} */}
                          </td>
                        </tr>
                      );
                    })
                  : // <h1 className="text-lg text-black  py-3 px-2">
                    //   No data to display!
                    // </h1>
                    ""}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      {/* <div className="absolute w-full bottom-0 left-0 z-[3]">
        <Footer />
      </div> */}
    </div>
  );
}

export default Report;

//  <section className="mt-[19px]">
// <div className="flex justify-between bg-[#313A44] px-3 py-3 text-white">
// <h3>Date</h3>
// <h3>Unit</h3>
// <h3>Qty.</h3>
// <h3>Rate</h3>
// </div>

// <div>
// {/* <div className="flex justify-between px-3 py-3">
//   <h5>2 July 2023</h5>
//   <h5>CMT</h5>
//   <h5>456.8</h5>
//   <h5>2897</h5>
// </div> */}

// </div>
// </section>

// {/* <section className="mt-[19px]">
//           <div className="flex justify-between bg-[#313A44] px-3 py-3 text-white">
//             <h3>Date</h3>
//             <h3>Unit</h3>
//             <h3>Qty.</h3>
//             <h3>Rate</h3>
//           </div>

//           <div className="flex bg-[#fff] px-3 py-3 text-black text-sm">
//             <h3 className="mr-7">22 July 2023</h3>
//             <h3>CMT</h3>
//             <h3>456.8</h3>
//             <h3>Rate</h3>
//           </div>

//           <div>
//             {/* <div className="flex justify-between px-3 py-3">
//   <h5>2 July 2023</h5>
//   <h5>CMT</h5>
//   <h5>456.8</h5>
//   <h5>2897</h5>
// </div> */}
//           </div>
//         </section> */}
