import React from "react";
import sucessIcon from "../../assets/successTick.png";
import crossIcon from "../../assets/popupCross.png";
import submitLogo from "../../assets/icon-submit.svg";
import cancelLogo from "../../assets/icon-cancel.svg";
function PunchPopup({ message, handlePunchCancelandSuccess }) {
  return (
    <div>
      <div className="w-[300px] relative h-[100px] flex flex-col justify-center gap-2 items-center bg-[#fff] rounded-md  border-[1px] border-gray-300 shadow-6xl">
        {/* <img src={sucessIcon} alt="logo" className="w-6" /> */}
        <p>{message}</p>

        {/* <div className="flex gap-3">
          <button
            type="button"
            class="inline-block rounded bg-success px-4 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#14a44d] transition duration-150 ease-in-out hover:bg-success-600 bg-green-500"
            onClick={() => handlePunchCancelandSuccess(true)}
          >
            Confirm
          </button>
          <button
            type="button"
            class="inline-block rounded bg-danger px-4 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 bg-red-500"
            onClick={() => handlePunchCancelandSuccess(false)}
          >
            Cancel
          </button>
        </div> */}
        <div className="flex justify-end items-center gap-2 ">
          {/* <button
              onClick={() => handleLeaveRequest()}
              className="rounded-md w-[20%] bg-gray-200 py-2 text-center text-sm   text-black mx-auto"
            >
              Submit
            </button> */}

          <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
            <img
              src={cancelLogo}
              alt="submit-logo"
              className="w-4 "
              onClick={() => handlePunchCancelandSuccess(false)}
            />
          </div>
          <div className="border-[1px] flex justify-center items-center border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
            <img
              src={submitLogo}
              alt="submit-logo"
              className="w-[18px]  "
              onClick={() => handlePunchCancelandSuccess(true)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PunchPopup;
