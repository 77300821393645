import React, { useState, useEffect } from "react";
import leftArrow from "../../assets/leftArrow.png";
import callIcon from "../../assets/call1.svg";
import superonLogo from "../../assets/superon.png";

import Form from "./Form";
import { useNavigate } from "react-router-dom";
import HelpModal from "../HelpModal";
import axios from "axios";
import config from "../Utils/config";

function CreateProfile() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const goBackToPhoneInputField = () => {
    navigate("/phone");
  };
  const handleModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={` bg-[#FF971A] ${isModalOpen && "overflow-hidden"} `}>
      <div className="flex justify-between items-center bg-orange-light z-20  p-5">
        {/* <img
          src={leftArrow}
          alt="left-arrow"
          className="h-7"
          onClick={() => goBackToPhoneInputField()}
        /> */}
        <div></div>
        {/* <h2 className="px-6 text-white text-xl font-semibold">
          Create Your Profile
        </h2> */}
        <img src={superonLogo} alt="menu-logo" className="w-[100px]" />
        <a href="tel:8527199811">
          <img
            src={callIcon}
            alt="help-logo"
            className="w-6"
            // onClick={() => setIsModalOpen(!isModalOpen)}
          />
        </a>
      </div>

      <div className="h-full bg-[#fafafa] overflow-auto">
        <Form />
      </div>
      {isModalOpen ? (
        <div
          className="absolute z-20  text-center top-[20%] left-[50%] mt-[50px]  mr-0 mb-0 ml-[-150px]"
          onBlur={() => setIsModalOpen(false)}
        >
          <HelpModal handleModal={handleModal} />
        </div>
      ) : (
        ""
      )}
      {isModalOpen ? (
        <div
          onClick={handleModal}
          className="w-full h-screen bg-slate-300 absolute top-0 opacity-30 z-[1]"
        ></div>
      ) : (
        ""
      )}
    </div>
  );
}

export default CreateProfile;
