import React from "react";

import CreateProfile from "./CreateProfile";

function index() {
  return (
    <>
      <CreateProfile />
    </>
  );
}

export default index;
