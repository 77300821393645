import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import loadingLogo_1 from "../../assets/loadingLogo_1.png";
import config from "../Utils/config";

function OtpInput({ userCredentials }) {
  // Input box auto focus Change Functionality
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);

  // count
  const [count, setCount] = useState(10);

  const handleKeyDown = (event, inputRef) => {
    if (event.target.value) {
      if (event.target.nextElementSibling) {
        inputRef.current.nextElementSibling.focus();
      }
    }
    if (event.key === "Backspace" && event.target.value === "") {
      if (event.target.previousElementSibling) {
        inputRef.current.previousElementSibling.focus();
      }
    }
  };

  console.log(userCredentials);
  const [otpMessage, setOtpMessage] = useState("");
  const [userOtp, setUserOtp] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    input1Ref.current.focus();
    // alert(`Your OTP is ${userCredentials.otp}`);
  }, []);

  const handleUser = async () => {
    // check user isValid
    let response = await axios.get(
      config.ipAddress + `/user/${userCredentials.mobile}`
    );
    if (response.data.data.status === "0") {
      navigate("/createprofile");
    }
    if (response.data.data.status === "1") {
      navigate("/pending");
    }
    if (response.data.data.status === "2") {
      navigate("/dashboard/target");
    }
  };
  const handleOtp = async () => {
    let otp = userOtp.one + userOtp.two + userOtp.three + userOtp.four;

    if (otp.length !== 4) {
      setOtpMessage("Please enter otp!");
      setTimeout(() => {
        setOtpMessage("");
      }, 3000);
      return;
    }

    let { data } = await axios.post(config.ipAddress + "/auth/otp/verify", {
      otp: otp,
      mobile: userCredentials.mobile,
    });
    console.log(data);
    if (data?.data?.status === 1) {
      localStorage.setItem("superonUserPhNum", userCredentials.mobile);
      // navigate("/createprofile");
      handleUser();
    } else if (data?.message === "Otp verified") {
      localStorage.setItem("superonUserPhNum", userCredentials.mobile);

      handleUser();
    } else {
      setOtpMessage("Wrong OTP, try again");

      setTimeout(() => {
        setOtpMessage("");
        setUserOtp({ one: "", two: "", three: "", four: "" });
      }, 3000);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    if (count === 0) {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [count]);

  return (
    <div className="px-3 h-full ">
      {/* <div className="pt-16">
        <h3 className="text-lg font-bold">ENTER THE OTP</h3>
        <p className="opacity-60 text-sm font-normal ">
          We have sent an OTP to {userCredentials.mobile || 1122334455}
        </p>
      </div> */}
      <section className="shadow-6xl h-full bg-white mt-4 px-4 py-4 mb-4 border-[1px] border-gray-300 rounded-[10px]">
        <div className="flex gap-2  mt-5">
          <div className="bg-[#FF971A] w-[2px] ml-2"></div>
          <div className="">
            <h1 className="text-md font-semibold">ENTER THE OTP</h1>
            <p className="text-sm">
              We have sent an OTP to {userCredentials.mobile}
            </p>
          </div>
        </div>
        <div className="flex justify-between mt-8">
          <input
            ref={input1Ref}
            type="tel"
            maxLength={1}
            className="border-solid text-center text-2xl font-bold border-2 focus:outline-none focus:border-[#12B0E8] w-16 h-16"
            value={userOtp.one}
            onChange={(e) => {
              setUserOtp({ ...userOtp, one: e.target.value });
              handleKeyDown(e, input1Ref);
            }}
            onKeyDown={(event) => handleKeyDown(event, input1Ref)}
          />
          <input
            ref={input2Ref}
            type="tel"
            maxLength={1}
            className="border-solid text-center text-2xl font-bold border-2 focus:outline-none focus:border-[#12B0E8] w-16 h-16"
            value={userOtp.two}
            onChange={(e) => {
              setUserOtp({ ...userOtp, two: e.target.value });
              handleKeyDown(e, input2Ref);
            }}
            onKeyDown={(event) => handleKeyDown(event, input2Ref)}
          />
          <input
            ref={input3Ref}
            type="tel"
            maxLength={1}
            className="border-solid text-center text-2xl font-bold border-2 focus:outline-none focus:border-[#12B0E8] w-16 h-16"
            value={userOtp.three}
            onChange={(e) => {
              setUserOtp({ ...userOtp, three: e.target.value });

              handleKeyDown(e, input3Ref);
            }}
            onKeyDown={(event) => handleKeyDown(event, input3Ref)}
          />
          <input
            ref={input4Ref}
            type="tel"
            maxLength={1}
            className="border-solid text-center text-2xl font-bold border-2 focus:outline-none focus:border-[#12B0E8] w-16 h-16"
            value={userOtp.four}
            onChange={(e) => {
              setUserOtp({ ...userOtp, four: e.target.value });
              handleKeyDown(e, input4Ref);
            }}
            onKeyDown={(event) => handleKeyDown(event, input4Ref)}
          />
        </div>
        <div className="flex justify-between items-center mt-4 px-1">
          <div className="flex justify-center items-center gap-2 ">
            <img src={loadingLogo_1} alt="logo" className="w-4 h-4" />
            <span className="text-[13px]">Auto Verifying</span>
          </div>

          <div className="">
            <span className="text-sm">
              Resend OTP in <b>{count}s</b>
            </span>
          </div>
        </div>
        <div>
          <button
            onClick={() => handleOtp()}
            className="mt-5 rounded-sm w-full bg-orange-light h-[53px] text-center text-lg font-semibold leading-7 text-white"
          >
            Proceed
          </button>
        </div>
        <div>
          <h3 className="text-center mt-4 text-base text-red-500">
            {otpMessage}
          </h3>
        </div>
      </section>
    </div>
  );
}

export default OtpInput;
