import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { NavLink, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";
import loadingGif from "../../assets/loading2.gif";

import upArrow from "../../assets/up-arrow.png";
// import Datepicker from "flowbite-datepicker/Datepicker";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

import "./target.css";
import axios from "axios";
import config from "../../Components/Utils/config";

let months = [
  { name: "January", target: 6456.5 },
  { name: "February", target: 27282 },
  { name: "March", target: 27282 },
  { name: "April", target: 27282 },
  { name: "May", target: 27282 },
  { name: "June", target: 27282 },
  { name: "July", target: 27282 },
  { name: "August", target: 27282 },
];

function Target() {
  const navigate = useNavigate();

  const phoneNum = localStorage.getItem("superonUserPhNum");

  const [category, setCategory] = useState([]);
  const [quarterData, setQuarterData] = useState([]);
  const [swiperArray, setSwiperArray] = useState([]);
  const [quarter, setQuarter] = useState("1");
  const [holdQuarterValue, setHoldQuarterValue] = useState("0");
  const [showLoading, setShowLoading] = useState(false);

  const [currentYearQuarter, setCurrentYearQuarter] = useState(
    new Date().getFullYear()
  );
  const [daysLeftInQuarter, setDaysLeftInQuarter] = useState(0);
  const [selectSlide, setSelectSlide] = useState(quarter);

  // new targe tData variable
  const [totalTarget, setTotalTarget] = useState(0);
  const [totalSale, setTotalSale] = useState(0);
  const [balance, setBalance] = useState(0);

  const [allData, setAllData] = useState([]);

  const [categoryValues, setCategoryValues] = useState({
    SSCWA: "00",
    MCAOP: "00",
    SSMCA: "00",
    MSP: "00",
    WAUX: "00",
  });
  const [slabStatus, setSlabStatus] = useState({
    SSCWA: "None",
    MCAOP: "None",
    SSMCA: "None",
    MSP: "None",
    WAUX: "None",
  });
  const [slabTargetValues, setSlabTargetValues] = useState({
    SSCWA: "00",
    MCAOP: "00",
    SSMCA: "00",
    MSP: "00",
    WAUX: "00",
  });

  const [userTargetData, setUserTargetData] = useState({});
  const [percentage, setPercentage] = useState(0);

  function getCurrentMonth() {
    const currentDate = new Date();
    const monthIndex = currentDate.getMonth();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const year = currentDate.getFullYear();
    const nextMonth = monthIndex + 1;
    const firstDayOfNextMonth = new Date(year, nextMonth, 1);
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const daysLeftInMonth = Math.floor(
      (firstDayOfNextMonth - currentDate) / millisecondsPerDay
    );
    return {
      monthName: monthNames[monthIndex],
      monthIndex: monthIndex,
      currentYear: year,
      daysLeftInMonth,
    };
  }

  const { monthName, monthIndex, currentYear, daysLeftInMonth } =
    getCurrentMonth();

  // handle User
  const handleUser = async () => {
    let phoneNum = localStorage.getItem("superonUserPhNum");
    if (phoneNum) {
      const { data } = await axios.get(config.ipAddress + `/user/${phoneNum}`);

      localStorage.setItem("superonUserName", data?.data?.name);

      if (data.data.status === "0") {
        navigate("/createprofile");
      }
      if (data.data.status === "1") {
        navigate("/pending");
      }
      if (data.data.status === "2") {
        navigate("/dashboard/target");
      }
    } else {
      navigate("/phone");
    }
  };

  // getCurrent Year and Month index
  function getCurrentYearAndMonthIndex() {
    const date = new Date();
    const year = date.getFullYear();
    const monthIndex = date.getMonth();

    return { year, monthIndex };
  }

  const getUserTargetData = async () => {
    const { year, monthIndex } = getCurrentYearAndMonthIndex();
    console.log(year, monthIndex);
    let data = {
      spid: phoneNum,
      month: quarter,
      year: year,
    };
    let res = await axios.post(config.ipAddress + `/target`, data);

    console.log(res?.data?.data?.quarter);
    setUserTargetData(res?.data?.data);
    setCategory(res?.data?.data?.category);
    setQuarterData(res?.data?.data?.quarter);
    let value = res?.data?.data;
    // console.log(value.achievedtarget);
    // let percentage = (value.achievedtarget / value.totaltarget) * 100;
    // if (percentage) {
    //   setPercentage(percentage);
    // } else {
    //   setPercentage(0);
    // }
  };

  // Target Data new api 🚀🚀🚀
  const getUserCurrentTargetData = async (quarterNum, quarterYear) => {
    setShowLoading(true);
    const { year, monthIndex } = getCurrentYearAndMonthIndex();

    if (quarterNum) {
      if (!quarterNum) return;
      setQuarter(quarterNum);
      let { currentQuarter } = getCurrentQuarterAndDays();

      console.log(quarterNum, currentQuarter);
      if (quarterNum !== currentQuarter) {
        setDaysLeftInQuarter(0);
      } else {
        getCurrentQuarterAndDays();
      }

      setQuarter(quarterNum);
      setCurrentYearQuarter(quarterYear);
    }

    console.log(quarterNum, quarterYear);
    let data = {
      spid: phoneNum,
      month: quarterNum || quarter,
      year: quarterYear || year + "",
    };

    try {
      let response = await axios.post(config.ipAddress + `/directtarget`, data);

      let allValues = response?.data?.data.category;
      console.log(response?.data?.data.category);

      setAllData(response?.data?.data.category);

      let totalTarget = 0;
      for (let i = 0; i < allValues.length; i++) {
        totalTarget += Number(allValues[i].totaltarget);
      }
      setTotalTarget(
        totalTarget.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );

      let totalSale = 0;
      for (let i = 0; i < allValues.length; i++) {
        totalSale += Number(allValues[i].totalachievement);
      }
      setTotalSale(
        totalSale.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
      console.log(totalTarget - totalSale, "skkskmskmskmkmskmskm");
      setBalance(
        (totalTarget - totalSale).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );

      let percentage = (totalSale / totalTarget) * 100;
      if (percentage) {
        setPercentage(Math.floor(percentage));
      } else {
        setPercentage(0);
      }
      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
    }

    // let allValues = response?.data?.data[0];

    // // "mcaotherdetailstotaltarget1": 16000000,
    // // "mspdetailstotaltarget2": 28100000,
    // // "sscwatotaltarget3": 19100000,
    // // "ssmcatotaltarget4": 8700000,

    // let totalTarget =
    //   allValues?.mcaotherdetailstotaltarget1 +
    //   allValues?.mspdetailstotaltarget2 +
    //   allValues?.sscwatotaltarget3 +
    //   allValues?.ssmcatotaltarget4 +
    //   allValues?.wauxtotaltarget5;
    // setTotalTarget(totalTarget);

    // setTotalSale(allValues?.totalachievement);
    // setBalance(totalTarget - allValues?.totalachievement);

    // // "mcaotherdetailstarget1": 2301678.6,
    // // "mspdetailstarget2": 5858113.4,
    // // "sscwatarget3": 2422770,
    // // "ssmcatarget4": 1419725,

    // // "mcaotherdetailstotaltarget1": 14200000,
    // //   "mspdetailstotaltarget2": 21800000,
    // //   "sscwatotaltarget3": 17600000,
    // //   "ssmcatotaltarget4": 4700000,
    // //   "wauxtotaltarget5": 275000,

    // setSlabTargetValues({
    //   ...slabTargetValues,
    //   SSCWA: allValues?.sscwatotaltarget3,
    //   MCAOP: allValues?.mcaotherdetailstotaltarget1,
    //   SSMCA: allValues?.ssmcatotaltarget4,
    //   MSP: allValues?.mspdetailstotaltarget2,
    //   WAUX: allValues?.wauxtotaltarget5,
    // });
    // console.log(slabTargetValues);

    // setCategoryValues({
    //   ...categoryValues,
    //   SSCWA: allValues?.sscwatarget3,
    //   MCAOP: allValues?.mcaotherdetailstarget1,
    //   SSMCA: allValues?.ssmcatarget4,
    //   MSP: allValues?.mspdetailstarget2,
    //   WAUX: allValues?.wauxtarget5,
    // });

    // let percentage = (allValues?.totalachievement / totalTarget) * 100;
    // if (percentage) {
    //   setPercentage(Math.floor(percentage));
    // } else {
    //   setPercentage(0);
    // }

    // // "mcaotherdetailsslab": "Top Slab",
    // // "mspslab": "Top Slab",
    // // "sscwaslab": "Top Slab",
    // // "ssmcaslab": "Top Slab"

    // setSlabStatus({
    //   ...slabStatus,
    //   SSCWA: allValues?.sscwaslab,
    //   MCAOP: allValues?.mcaotherdetailsslab,
    //   SSMCA: allValues?.ssmcaslab,
    //   MSP: allValues?.mspslab,
    //   WAUX: allValues?.wauxslab,
    // });

    // setShowLoading(false);
  };

  const getUserTargetDataByQuarter = async (quarterValue, quarterYear) => {
    if (!quarterValue) return;
    setQuarter(quarterValue);
    let { currentQuarter } = getCurrentQuarterAndDays();

    if (quarterValue !== currentQuarter) {
      setDaysLeftInQuarter(0);
    } else {
      getCurrentQuarterAndDays();
    }

    setSelectSlide(quarterValue);

    setQuarter(quarterValue);
    const { year, monthIndex } = getCurrentYearAndMonthIndex();

    // setCurrentYearQuarter(
    //   quarter === 4 ? quarterData[0]?.year : quarterData[0]?.year - 1
    // );
    setCurrentYearQuarter(quarterYear);
    let data = {
      spid: phoneNum,
      month: quarterValue,
      year: year,
    };
    let res = await axios.post(config.ipAddress + `/target`, data);
    console.log(res);
    console.log(res?.data?.data);
    setUserTargetData(res?.data?.data);
    setCategory(res?.data?.data?.category);
    let value = res?.data?.data;
    // console.log(value.achievedtarget);
    let percentage = (value.achievedtarget / value.totaltarget) * 100;
    if (percentage) {
      setPercentage(percentage);
    } else {
      setPercentage(0);
    }

    // alert("You are viewing Quarter " + quarterValue);
  };

  // convert Number with Comma
  const numberWithCommas = (numberStr) => {
    if (!numberStr) return "00";

    let num = Number(numberStr);

    const formattedNumber = (num / 100000).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    console.log(formattedNumber); // Output: 2,422.70

    return formattedNumber;
  };
  const numberWithCommas2 = (numberStr) => {
    if (!numberStr) return "00";

    let num = Number(numberStr);

    const formattedNumber = (num / 100000).toLocaleString("en-US", {});
    console.log(formattedNumber); // Output: 2,422.70

    return formattedNumber;
  };

  // get Current Quarter 🚀🚀🚀
  function getCurrentQuarterAndDays() {
    // get the current month (0-11)
    const currentMonth = new Date().getMonth();

    // divide the 12 months into quarters
    const quarters = {
      1: [3, 4, 5], // Quarter 1: April, May, June
      2: [6, 7, 8], // Quarter 2: July, August, September
      3: [9, 10, 11], // Quarter 3: October, November, December
      4: [0, 1, 2], // Quarter 4: January, February, March
    };

    // get the current quarter based on the current month
    let currentQuarter;
    for (const quarter in quarters) {
      if (quarters[quarter].includes(currentMonth)) {
        currentQuarter = quarter;
        break;
      }
    }

    // calculate the number of days left in the current quarter
    const currentQuarterMonths = quarters[currentQuarter];
    const currentQuarterEndMonth =
      currentQuarterMonths[currentQuarterMonths.length - 1];
    const currentQuarterEndDate = new Date(
      new Date().getFullYear(),
      currentQuarterEndMonth + 1,
      0
    );
    const daysLeftInQuarter = Math.ceil(
      (currentQuarterEndDate - new Date()) / (1000 * 60 * 60 * 24)
    );

    // console.log("Current Quarter:", typeof currentQuarter);
    // console.log("Days left in Current Quarter:", daysLeftInQuarter);
    setQuarter(currentQuarter);
    setHoldQuarterValue(currentQuarter);
    setDaysLeftInQuarter(daysLeftInQuarter);
    return { currentQuarter, daysLeftInQuarter };
  }

  // const getCategory = async () => {
  //   let data = {};
  //   let response = await axios.post(config.ipAddress + `/categories`);
  //   console.log(response?.data?.data);
  //   setCategory(response?.data?.data);
  // };

  useEffect(() => {
    let phoneNum = localStorage.getItem("superonUserPhNum");
    console.log(phoneNum);
    if (!phoneNum) {
      navigate("/phone");
    }
  }, []);

  const getExactQuarterArray = () => {
    const { currentQuarter, daysLeftInQuarter } = getCurrentQuarterAndDays();

    if (currentQuarter === "1") {
      return setSwiperArray([""]);
    }
    if (currentQuarter === "2") {
      return setSwiperArray(["", ""]);
    }
    if (currentQuarter === "3") {
      return setSwiperArray(["", "", ""]);
    }
    if (currentQuarter === "4") {
      return setSwiperArray(["", "", "", ""]);
    }
  };

  const converNumber = (numStr) => {
    const decimalPart = numStr.toFixed(2);
    const [integerPart, fractionalPart] = decimalPart.split(".");
    const paddedIntegerPart = integerPart.padStart(2, "0");
    return `${paddedIntegerPart}.${fractionalPart}`;
  };

  useEffect(() => {
    handleUser();
    getUserCurrentTargetData();
    getUserTargetData();
    // getDealers();
    // getCategory();
    // getExactQuarterArray();
    getCurrentQuarterAndDays();
  }, []);

  const swiperRef = useRef(null);
  // console.log(swiperRef.current.swiper.realIndex);

  const [currentSlide, setCurrentSlide] = useState(0);

  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(false);

  const updateIndex = useCallback(
    () => setCurrentSlide(swiperRef.current.swiper.realIndex),
    []
  );

  // Add eventlisteners for swiper after initializing
  // useEffect(() => {
  //   const swiperInstance = swiperRef.current.swiper;

  //   if (swiperInstance) {
  //     swiperInstance.on("slideChange", updateIndex);
  //   }

  //   return () => {
  //     if (swiperInstance) {
  //       swiperInstance.off("slideChange", updateIndex);
  //     }
  //   };
  // }, [updateIndex]);

  return (
    <div>
      <div className="bg-[#fafafa] h-[89%]  w-full py-[17px] px-[13px] overflow-auto">
        <div className="bg-white  py-4 pr-4 rounded-[10px] shadow-6xl border-[1px] border-gray-300">
          <div className="flex gap-2">
            <div className="bg-[#FF971A] w-[2px] ml-4 rounded-full"></div>
            <div className="-space-y-1 ">
              <h1 className="text-lg  ">Target</h1>
              <p>
                Quarter {quarter}, {currentYearQuarter}
              </p>
            </div>
          </div>

          <section className="flex justify-between pl-7   mt-6">
            <div className="space-y-[-1px]">
              <h1 className="text-[#EC8C17]  text-sm">TARGET</h1>
              <p className=" text-sm">
                {/* ₹ {numberWithCommas(userTargetData.totaltarget) || "000000"} lacs */}
                {/* ₹ {numberWithCommas(totalTarget) || "000000"} lacs */}₹{" "}
                {totalTarget || "00"} lacs
              </p>
              <p className="text-[10px]">(for Quarter {quarter})</p>
            </div>

            <div className="space-y-[-1px]">
              <h1 className="text-[#EC8C17]  text-sm">SALE</h1>
              <p className=" text-sm">
                {/* ₹ {numberWithCommas(userTargetData.achievedtarget) || "0000000"} lacs */}
                {/* ₹ {numberWithCommas(totalSale) || "0000000"} lacs */}₹{" "}
                {totalSale || "00"} lacs
              </p>
              <p className="text-[10px]">(Invoice)</p>
            </div>

            <div className="space-y-[-1px]">
              <h1 className="text-[#EC8C17]  text-sm">BALANCE</h1>
              <p className=" text-sm">
                {/* ₹{" "}
              {numberWithCommas(
                userTargetData.totaltarget - userTargetData.achievedtarget
              ) || "000000"}{" "}
              lacs */}
                {/* ₹ {numberWithCommas(balance) || "000000"} lacs */}₹{" "}
                {balance || "00"} lacs
              </p>
              <p className="text-[10px]">
                ({daysLeftInQuarter} days to achieve)
              </p>
            </div>
          </section>

          {/* second container */}
          <div className=" pl-7  mt-6 flex flex-row-reverse justify-between  items-center ">
            <div className="w-[130px]  my-auto">
              <CircularProgressbarWithChildren
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  // rotation: 0.25,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "round",

                  // Text size
                  textSize: "16px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: `#009788`,
                  textColor: "#f88",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                })}
                value={percentage}
              >
                <div
                  style={{ fontSize: 12, marginTop: -5 }}
                  className="text-start"
                >
                  <div className="text-center text-base ">
                    <span className=" text-lg font-medium text-[#009788]">
                      {percentage}%
                    </span>
                    <h3 className="">Achieved</h3>
                  </div>
                </div>
              </CircularProgressbarWithChildren>
            </div>

            <div className="font-medium space-y-3">
              {/* {category.length &&
              category.map((item, index) => {
                return (
                  <div key={index} className="text-[13px] flex font-semibold">
                    <h3 className="min-w-[50px]">
                      {item.categoryname
                        .replace(" DETAILS", "")
                        .replace("OTHER", "OP")}
                    </h3>

                    <span className="text-[#009788]  ml-3">
                      ₹ {numberWithCommas(item.totalachievement)} L
                    </span>
                  </div>
                );
              })} */}

              {allData.length
                ? allData.map((item, index) => {
                    return (
                      <div key={index} className="text-[13px] flex ">
                        <h3 className="min-w-[50px] text-[#EC8C17]">
                          {item.categoryname}
                        </h3>
                        <span className="text-black  ml-2 min-w-[50px]">
                          {/* ₹ {numberWithCommas(categoryValues.SSCWA)} L  text-[#009788]*/}
                          {/* {item.totalachievement} L */}
                          {item.totalachievement.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                          L
                        </span>
                        <span className="text-[#009788] ml-[6px]">
                          (
                          {Math.ceil(
                            (item.totalachievement / item.totaltarget) * 100 ===
                              Infinity
                              ? 100
                              : (item.totalachievement / item.totaltarget) * 100
                          ) || 0}
                          %)
                        </span>
                      </div>
                    );
                  })
                : ""}

              {/* <div className="text-[13px] flex ">
                <h3 className="min-w-[50px]">SS CWA</h3>
                <span className="text-[#009788]  ml-3">
                  ₹ {numberWithCommas(categoryValues.SSCWA)} L
                </span>
              </div>
              <div className="text-[13px] flex ">
                <h3 className="min-w-[50px]">MCA OP</h3>
                <span className="text-[#009788]  ml-3">
                  ₹ {numberWithCommas(categoryValues.MCAOP)} L
                </span>
              </div>

              <div className="text-[13px] flex ">
                <h3 className="min-w-[50px]">SS MCA</h3>
                <span className="text-[#009788]  ml-3">
                  ₹ {numberWithCommas(categoryValues.SSMCA)} L
                </span>
              </div>
              <div className="text-[13px] flex ">
                <h3 className="min-w-[50px]">MSP</h3>
                <span className="text-[#009788]  ml-3">
                  ₹ {numberWithCommas(categoryValues.MSP)} L
                </span>
              </div>
              <div className="text-[13px] flex ">
                <h3 className="min-w-[50px]">W AUX</h3>
                <span className="text-[#009788]  ml-3">
                  ₹ {numberWithCommas(categoryValues.WAUX)} L
                </span>
              </div> */}

              {/* <h3 className="text-[13px]">
              <span className="text-[#2C6BE3]  mr-4">
                {numberWithCommas(category[1]?.totalachievement)} L
              </span>
              {category[1]?.categoryname}
            </h3>
            <h3 className="text-[13px]">
              <span className="text-[#F19919]  mr-5">
                {numberWithCommas(category[2]?.totalachievement)} L
              </span>
              {category[2]?.categoryname}
            </h3>
            <h3 className="text-[13px]">
              <span className="text-[#C40030]  mr-3">
                {numberWithCommas(category[3]?.totalachievement)} L
              </span>
              {category[3]?.categoryname}
            </h3> */}
            </div>
          </div>
          {/* third container */}

          {/* fourth container */}

          {/* <section className="text-center text-black  text-md mt-6">
          <NavLink
            to={`/dashboard/target/report/${quarter}/${
              quarter === "4" ? currentYearQuarter - 1 : currentYearQuarter
            }`}
          >
            <h className="inline-block mr-1">View Report</h>
            <span className="inline text-sm">&gt;</span>
          </NavLink>
        </section> */}

          {/* fifth container */}
          {/* 
        <section className="mt-2 px-2">
          <div className="text-center border-t-2 border-dotted border-gray-500 ">
            <h2 className="font-semibold mt-3">
              Total Target July
              <span className="text-[#55595A]">
                &nbsp; {userTargetData.totaltarget} MT
              </span>
            </h2>
          </div>
        </section> */}
        </div>

        {/* Slider Container */}

        {/* <div className="my-4">
        <h4 className="text-center  font-semibold">
          <span className="">&lt;</span> July Target
          <span className="text-[#FB892E]"> &gt;</span>
        </h4>
      </div> */}

        {/* Category DEtails */}
        {/* <section className="mt-4 bg-[#fff] px-4 py-4 shadow-md rounded-md border-[1px] border-gray-300">
        <div className="flex gap-2 mb-2 bg-[#fff]">
          <div className="bg-[#FF971A] w-[2px] rounded-full"></div>
          <div className="">
            <h1 className="text-base ">Slab Status </h1>
          </div>
        </div>
        <main className="flex justify-between  pl-[10px] bg-[#fff]">
          <div className="text-center flex flex-col gap-1">
            <h3 className="text-[13px] ">SS CWA</h3>
            <span className="text-[#009788]  text-[12px]">
              {slabStatus.SSCWA}
            </span>
          </div>
          <div className="text-center flex flex-col gap-1">
            <h3 className="text-[13px] ">MCA OP</h3>
            <span className="text-[#009788]  text-[12px]">
              {slabStatus.MCAOP}
            </span>
          </div>
          <div className="text-center flex flex-col gap-1">
            <h3 className="text-[13px] ">SS MCA</h3>
            <span className="text-[#009788]  text-[12px]">
              {slabStatus.SSMCA || "None"}
            </span>
          </div>
          <div className="text-center flex flex-col gap-1">
            <h3 className="text-[13px] ">MSP</h3>
            <span className="text-[#009788]  text-[12px]">
              {slabStatus.MSP}
            </span>
          </div>
          <div className="text-center flex flex-col gap-1">
            <h3 className="text-[13px] ">W AUX</h3>
            <span className="text-[#009788]  text-[12px]">
              {slabStatus.WAUX || "None"}
            </span>
          </div>
        </main>
      </section> */}

        <section
          className={`bg-white ${
            allData.length === 0 ? "py-4" : "pt-4"
          } px-4 mt-4  shadow-md rounded-[10px] border-[1px] border-gray-300`}
        >
          <div className="flex gap-2  bg-[#fff] ">
            <div className="bg-[#FF971A] w-[2px] rounded-full"></div>
            <div className="">
              <h1 className="text-base ">Slab Status </h1>
            </div>
          </div>
          <Swiper
            // onSwiper={setSwiperRef}
            // initialSlide={1}
            // onRealIndexChange={2}
            slidesPerView={4}
            // centeredSlides={true}
            spaceBetween={4}
            navigation={true}
            scrollbar={{
              hide: true,
            }}
            modules={[Scrollbar]}
            className="mySwiper"
            ś
            ref={swiperRef}
          >
            {allData.length
              ? allData.map((item, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      className="  bg-white  rounded-md text-center"
                      // onClick={() => getUserTargetDataByQuarter(quarterData[0]?.quarter)}
                    >
                      <div className=" p-2">
                        <div className=" ">
                          {/* <div className="line bg-[#FF971A] w-[2px] mr-2 rounded-full"></div> */}

                          <h3 className="text-sm text-black text-center ">
                            {item.categoryname}
                          </h3>
                        </div>
                        <p className="text-[12px] mt-1 text-center">
                          {/* ₹ {numberWithCommas2(slabTargetValues.SSCWA)} L */}
                          ₹{" "}
                          {(item.totaltarget &&
                            item.totaltarget.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })) ||
                            "00"}{" "}
                          L
                        </p>
                        {/* <p className="text-[#009788]  text-[12px] mt-1 text-center">
      {slabStatus.SSCWA}
    </p> */}
                      </div>
                    </SwiperSlide>
                  );
                })
              : ""}
          </Swiper>
        </section>

        {/* quarters last four */}
        <section className="mt-4 bg-white shadow-md rounded-md pr-4 border-[1px] border-gray-300">
          <main className="bg-white py-2 rounded-md pl-4 flex items-center gap-2">
            {/* for quarter 1 */}
            <div className="w-[2.4px] h-[25px]  bg-orange-light rounded-full"></div>
            {holdQuarterValue === "1" && (
              <section className="flex justify-between items-center  gap-1 w-full">
                <div
                  className={`flex   py-2 `}
                  // onClick={() => getUserTargetDataByQuarter("2", currentYear - 1)}
                  onClick={() => getUserCurrentTargetData("2", currentYear - 1)}
                >
                  <h3
                    className={`text-sm text-center text-black   ${
                      quarter == "2" ? "border-orange-light" : "border-white"
                    } border-b-[2px] `}
                  >
                    Q2, {currentYear - 1}
                  </h3>

                  {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
                </div>

                <div className="w-[2.4px] h-[20px]  bg-gray-300"></div>
                <div
                  className={`flex    py-2  `}
                  // onClick={() => getUserTargetDataByQuarter("3", currentYear - 1)}
                  onClick={() => getUserCurrentTargetData("3", currentYear - 1)}
                >
                  <h3
                    className={`text-sm text-center text-black  ${
                      quarter == "3" ? "border-orange-light" : "border-white"
                    } border-b-[2px] `}
                  >
                    Q3, {currentYear - 1}
                  </h3>

                  {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
                </div>
                <div className="w-[2.4px] h-[20px]  bg-gray-300"></div>

                <div
                  className={`flex    py-2 `}
                  // onClick={() => getUserTargetDataByQuarter("4", currentYear - 1)}
                  onClick={() => getUserCurrentTargetData("4", currentYear - 1)}
                >
                  {/* <div className="w-[2.4px] bg-gray-300 mr-2"></div> */}

                  <h3
                    className={`text-sm text-center text-black   ${
                      quarter == "4" ? "border-orange-light" : "border-white"
                    } border-b-[2px] `}
                  >
                    Q4, {currentYear - 1}
                  </h3>

                  {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
                </div>
                <div className="w-[2.4px] h-[20px]  bg-gray-300"></div>

                <div
                  className={`flex    py-2  `}
                  // onClick={() => getUserTargetDataByQuarter("1", currentYear)}
                  onClick={() => getUserCurrentTargetData("1", currentYear)}
                >
                  {/* <div className="w-[2px] bg-gray-300 mr-2"></div> */}

                  <h3
                    className={`text-sm text-center text-black   ${
                      quarter == "1" ? "border-orange-light" : "border-white"
                    } border-b-[2px] `}
                  >
                    Q1, {currentYear}
                  </h3>

                  {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
                </div>
              </section>
            )}

            {/* for quarter 2 */}
            {holdQuarterValue === "2" && (
              <section className="flex justify-between items-center  gap-1 w-full">
                <div
                  className={`flex   py-2 `}
                  // onClick={() => getUserTargetDataByQuarter("2", currentYear - 1)}
                  onClick={() => getUserCurrentTargetData("3", currentYear - 1)}
                >
                  <h3
                    className={`text-sm text-center text-black   ${
                      quarter == "3" ? "border-orange-light" : "border-white"
                    } border-b-[2px] `}
                  >
                    Q3, {currentYear - 1}
                  </h3>

                  {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
                </div>

                <div className="w-[2.4px] h-[20px]  bg-gray-300"></div>
                <div
                  className={`flex    py-2  `}
                  // onClick={() => getUserTargetDataByQuarter("3", currentYear - 1)}
                  onClick={() => getUserCurrentTargetData("4", currentYear - 1)}
                >
                  <h3
                    className={`text-sm text-center text-black  ${
                      quarter == "4" ? "border-orange-light" : "border-white"
                    } border-b-[2px] `}
                  >
                    Q4, {currentYear - 1}
                  </h3>

                  {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
                </div>
                <div className="w-[2.4px] h-[20px]  bg-gray-300"></div>

                <div
                  className={`flex    py-2 `}
                  // onClick={() => getUserTargetDataByQuarter("4", currentYear - 1)}
                  onClick={() => getUserCurrentTargetData("1", currentYear)}
                >
                  {/* <div className="w-[2.4px] bg-gray-300 mr-2"></div> */}

                  <h3
                    className={`text-sm text-center text-black   ${
                      quarter == "1" ? "border-orange-light" : "border-white"
                    } border-b-[2px] `}
                  >
                    Q1, {currentYear}
                  </h3>

                  {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
                </div>
                <div className="w-[2.4px] h-[20px]  bg-gray-300"></div>

                <div
                  className={`flex    py-2  `}
                  // onClick={() => getUserTargetDataByQuarter("1", currentYear)}
                  onClick={() => getUserCurrentTargetData("2", currentYear)}
                >
                  {/* <div className="w-[2px] bg-gray-300 mr-2"></div> */}

                  <h3
                    className={`text-sm text-center text-black   ${
                      quarter == "2" ? "border-orange-light" : "border-white"
                    } border-b-[2px] `}
                  >
                    Q2, {currentYear}
                  </h3>

                  {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
                </div>
              </section>
            )}

            {/* for quarter 3 */}
            {holdQuarterValue === "3" && (
              <section className="flex justify-between items-center  gap-1 w-full">
                <div
                  className={`flex   py-2 `}
                  // onClick={() => getUserTargetDataByQuarter("2", currentYear - 1)}
                  onClick={() => getUserCurrentTargetData("4", currentYear - 1)}
                >
                  <h3
                    className={`text-sm text-center text-black   ${
                      quarter == "4" ? "border-orange-light" : "border-white"
                    } border-b-[2px] `}
                  >
                    Q4, {currentYear - 1}
                  </h3>

                  {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
                </div>

                <div className="w-[2.4px] h-[20px]  bg-gray-300"></div>
                <div
                  className={`flex    py-2  `}
                  // onClick={() => getUserTargetDataByQuarter("3", currentYear - 1)}
                  onClick={() => getUserCurrentTargetData("1", currentYear)}
                >
                  <h3
                    className={`text-sm text-center text-black  ${
                      quarter == "1" ? "border-orange-light" : "border-white"
                    } border-b-[2px] `}
                  >
                    Q1, {currentYear}
                  </h3>

                  {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
                </div>
                <div className="w-[2.4px] h-[20px]  bg-gray-300"></div>

                <div
                  className={`flex    py-2 `}
                  // onClick={() => getUserTargetDataByQuarter("4", currentYear - 1)}
                  onClick={() => getUserCurrentTargetData("2", currentYear)}
                >
                  {/* <div className="w-[2.4px] bg-gray-300 mr-2"></div> */}

                  <h3
                    className={`text-sm text-center text-black   ${
                      quarter == "2" ? "border-orange-light" : "border-white"
                    } border-b-[2px] `}
                  >
                    Q2, {currentYear}
                  </h3>

                  {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
                </div>
                <div className="w-[2.4px] h-[20px]  bg-gray-300"></div>

                <div
                  className={`flex    py-2  `}
                  // onClick={() => getUserTargetDataByQuarter("1", currentYear)}
                  onClick={() => getUserCurrentTargetData("3", currentYear)}
                >
                  {/* <div className="w-[2px] bg-gray-300 mr-2"></div> */}

                  <h3
                    className={`text-sm text-center text-black   ${
                      quarter == "3" ? "border-orange-light" : "border-white"
                    } border-b-[2px] `}
                  >
                    Q3, {currentYear}
                  </h3>

                  {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
                </div>
              </section>
            )}

            {/* for quarter 4 */}
            {holdQuarterValue === "4" && (
              <section className="flex justify-between items-center  gap-1 w-full">
                <div
                  className={`flex   py-2 `}
                  // onClick={() => getUserTargetDataByQuarter("2", currentYear - 1)}
                  onClick={() => getUserCurrentTargetData("1", currentYear - 1)}
                >
                  <h3
                    className={`text-sm text-center text-black   ${
                      quarter == "1" ? "border-orange-light" : "border-white"
                    } border-b-[2px] `}
                  >
                    Q1, {currentYear - 1}
                  </h3>

                  {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
                </div>

                <div className="w-[2.4px] h-[20px]  bg-gray-300"></div>
                <div
                  className={`flex    py-2  `}
                  // onClick={() => getUserTargetDataByQuarter("3", currentYear - 1)}
                  onClick={() => getUserCurrentTargetData("2", currentYear - 1)}
                >
                  <h3
                    className={`text-sm text-center text-black  ${
                      quarter == "2" ? "border-orange-light" : "border-white"
                    } border-b-[2px] `}
                  >
                    Q2, {currentYear - 1}
                  </h3>

                  {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
                </div>
                <div className="w-[2.4px] h-[20px]  bg-gray-300"></div>

                <div
                  className={`flex    py-2 `}
                  // onClick={() => getUserTargetDataByQuarter("4", currentYear - 1)}
                  onClick={() => getUserCurrentTargetData("3", currentYear - 1)}
                >
                  {/* <div className="w-[2.4px] bg-gray-300 mr-2"></div> */}

                  <h3
                    className={`text-sm text-center text-black   ${
                      quarter == "3" ? "border-orange-light" : "border-white"
                    } border-b-[2px] `}
                  >
                    Q3, {currentYear - 1}
                  </h3>

                  {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
                </div>
                <div className="w-[2.4px] h-[20px]  bg-gray-300"></div>

                <div
                  className={`flex    py-2  `}
                  // onClick={() => getUserTargetDataByQuarter("1", currentYear)}
                  onClick={() => getUserCurrentTargetData("4", currentYear - 1)}
                >
                  {/* <div className="w-[2px] bg-gray-300 mr-2"></div> */}

                  <h3
                    className={`text-sm text-center text-black   ${
                      quarter == "4" ? "border-orange-light" : "border-white"
                    } border-b-[2px] `}
                  >
                    Q4, {currentYear - 1}
                  </h3>

                  {/* <p className="text-[12px] font-semibold">100% in Quarter 3</p> */}
                </div>
              </section>
            )}
          </main>
        </section>
      </div>
      {showLoading && (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-50px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <img src={loadingGif} className="w-[100px]" alt="loading-logo" />
        </div>
      )}

      {showLoading && (
        <div
          // onClick={() => setIsModalOpen(false)}
          className="w-full h-[100%] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      )}
    </div>
  );
}

export default Target;
