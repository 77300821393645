// import Webcam from "react-webcam";
// import React, { useRef } from "react";

// function Capture() {
//   const videoConstraints = {
//     width: 1280,
//     height: 720,
//     // facingMode: "user",
//     facingMode: { exact: "environment" },
//   };
//   const webRef = useRef(null);
//   const showImage = () => {
//     console.log(webRef.current.getScreenshot());
//   };
//   return (
//     <div>
//       React webcam
//       <Webcam
//         ref={webRef}
//         audio={false}
//         height={720}
//         screenshotFormat="image/jpeg"
//         width={400}
//         videoConstraints={videoConstraints}
//         pau
//       />
//       <button onClick={() => showImage()}>Capture</button>
//       <iframe
//         // src="https://my-website.com/page-with-webcam"
//         allow="camera; microphone;"
//       />
//     </div>
//   );
// }

// export default Capture;

import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";

function Capture() {
  const [image, setImage] = useState(null);
  const [showCaptureButton, setShowCaptureButton] = useState(true);
  const [showRetakeButton, setShowRetakeButton] = useState(false);

  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    setShowCaptureButton(false);
    setShowRetakeButton(true);
  }, [webcamRef]);

  const retake = useCallback(() => {
    setImage(null);
    setShowCaptureButton(true);
    setShowRetakeButton(false);
  }, []);

  const done = useCallback(() => {
    // Do something with the captured image
    console.log(image);
  }, [image]);

  return (
    <div>
      <Webcam audio={false} ref={webcamRef} />
      {showCaptureButton && <button onClick={capture}>Capture</button>}
      {showRetakeButton && <button onClick={retake}>Retake</button>}
      {image && <button onClick={done}>Done</button>}
    </div>
  );
}

export default Capture;
