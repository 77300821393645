import React, { useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// import WelcomePage from "../Components/welcomePage/WelcomePage";
// import Footer from "./Footer";
import Navbar from "./Navbar";
// import Target from "./Target/Target";

function Dashboard() {
  const navigate = useNavigate();
  const indexRef = useRef();
  useEffect(() => {
    // indexRef.current.style.overflow = "hidden";
    // navigate("/dashboard/target");
  });
  return (
    <div className="w-screen h-[100dvh] overflow-auto" ref={indexRef}>
      <div className="sticky top-0 left-0 z-[3]">
        <Navbar />
      </div>
      <Outlet />
      {/* <div className="sticky bottom-0 left-0 z-[3]">
        <Footer />
      </div> */}
    </div>
  );
}

export default Dashboard;
