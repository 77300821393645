import { Routes, Route } from "react-router-dom";
import Attendance from "./Components/Attendance/Attendance";
import CheckInForm from "./Components/Attendance/AttenDemo";
import Capture from "./Components/Attendance/Capture";
import CreateProfile from "./Components/createProfile";
import Pending from "./Components/Pending";
import PhoneInputField from "./Components/phoneInput";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import Request from "./Components/request/Request";
import Terms from "./Components/Terms&Condition/Terms";
import ViewAttenddanceRecord from "./Components/ViewAllAttendance/ViewAttenddanceRecord";
import ViewDetails from "./Components/ViewAllAttendance/ViewDetails";
import WelcomePage from "./Components/welcomePage/WelcomePage";
import Dashboard from "./Dashboard/Dashboard";
import Report from "./Dashboard/Report/Report";
import Target from "./Dashboard/Target/Target";
import InvoiceDetails from "./Components/invoice/InvoiceDetails";
import Leads from "./Components/Leads"
// import Camera from "./Dashboard/Report/Camera";
// import MobileDetect from "./Components/testFolder/MobileDetect";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);

    document.onkeydown = (e) => {
      // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + Shift + C, Ctrl + U
      if (
        e.keyCode === 123 ||
        (e.ctrlKey &&
          e.shiftKey &&
          (e.keyCode === 73 || e.keyCode === 74 || e.keyCode === 67)) ||
        (e.ctrlKey && e.keyCode === 85)
      ) {
        e.preventDefault();
        return false;
      }
    };

    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/phone" element={<PhoneInputField />} />
        <Route path="/createprofile" element={<CreateProfile />} />
        <Route path="/pending" element={<Pending />} />
        <Route path="/dashboard/*" element={<Dashboard />}>
          <Route path="" element={<Target />} />
          <Route path="target" element={<Target />} />
          <Route path="attendance" element={<Attendance />} />
        </Route>
        <Route
          path="/dashboard/target/report/:quarter/:year"
          element={<Report />}
        />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/request" element={<Request />} />
        <Route path="/attendance/record" element={<ViewAttenddanceRecord />} />
        <Route
          path="/viewdetails/:date/:punchin/:punchout"
          element={<ViewDetails />}
        />
        <Route path="/demo" element={<CheckInForm />} />
        <Route path="/leads" element={<Leads />} />
        <Route path="/capture" element={<Capture />} />
        <Route path="/invoice/:number" element={<InvoiceDetails />} />
        {/* <Route path="/camera" element={<Camera />} />
        <Route path="/mobile" element={<MobileDetect />} /> */}
      </Routes>
    </>
  );
}

export default App;
