import React, { useContext } from "react";
import crossIcon from "../assets/cross.png";
import userIcon from "../assets/sidebar-icon/user-icon.png";
import targetIcon from "../assets/sidebar-icon/target.png";
import attendanceIcon from "../assets/sidebar-icon/attendance.png";
import dailyWorkingIcon from "../assets/sidebar-icon/daily-working.png";
import requestIcon from "../assets/sidebar-icon/request.png";
import leadsIcon from "../assets/leads.svg";
import termsIcon from "../assets/sidebar-icon/terms-icon.png";
import logoutIcon from "../assets/sidebar-icon/logout-icon.png";
import privacyIcon from "../assets/sidebar-icon/policy-icon.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import PunchPopup from "../Components/popupModal/PunchPopup";
import { modalContext } from "./Navbar";

const Sidebar = ({ handleSideBar }) => {
  const navigate = useNavigate();

  const {
    setIsModalOpen2,
    handlePunchCancelandSuccess,
    handlePunchOutCancelandSuccess,
  } = useContext(modalContext);
  // console.log(modalValue);

  const [phoneNum, setPhoneNum] = useState("");
  const [userName, setUserName] = useState("");
  // const [isModalOpen, setIsModalOpen] = useState(true);

  const handleLogOut = (e) => {
    // let isLogout = window.confirm("Confirm logout ?");

    e.preventDefault();
    setIsModalOpen2(true);
    handleSideBar();
    // let isCancelLogout = handlePunchCancelandSuccess();
    // let isConfirmLogout = handlePunchOutCancelandSuccess();
    // console.log(isCancelLogout);
    // if (!isCancelLogout) {
    //   // setIsModalOpen2(false);
    // }

    // if (isConfirmLogout) {
    //   // setIsModalOpen2(false);
    //   localStorage.removeItem("superonUserPhNum");
    //   localStorage.removeItem("superonUserName");

    //   navigate("/phone");
    // }

    // if (isLogout) {
    //   localStorage.removeItem("superonUserPhNum");
    //   localStorage.removeItem("superonUserName");

    //   navigate("/phone");
    // }
  };

  useEffect(() => {
    setPhoneNum(localStorage.getItem("superonUserPhNum"));
    setUserName(localStorage.getItem("superonUserName"));
  }, []);
  return (
    <>
      <div className="flex items-center gap-3 bg-[#FF971A] px-4 py-[15px] overflow-hidden">
        <div>{/* <img src={userIcon} alt="userLogo" /> */}</div>
        <div className="text-white text-sm">
          <h1 className="">{userName || "User Name"}</h1>
          <p className="">Mobile: {phoneNum}</p>
          {/* <NavLink className="bg-[rgb(195,187,187)] p-[2px] text-[12px]">
            Edit Profile
          </NavLink> */}
        </div>
      </div>
      <aside
        // onBlur={() => handleSideBar()}
        className="flex flex-col w-64 h-full  py-6 overflow-y-auto border-r rtl:border-r-0 rtl:border-l bg-white "
      >
        <div className="flex flex-col justify-between flex-1  h-full overflow-y-auto bg-white">
          <nav className=" -mx-3 space-y-5  h-full overflow-y-auto">
            <NavLink
              className="flex items-center px-9  text-gray-600 transition-colors duration-300 transform rounded-lg  "
              to="/dashboard/target"
              onClick={handleSideBar}
            >
              <img src={targetIcon} alt="logo" className="w-6" />
              <span className="mx-2 text-sm font-medium text-black">
                Target
              </span>
            </NavLink>
            <hr className="h-[1px] bg-gray-400" />

            <NavLink
              className="flex items-center px-9 text-gray-600 transition-colors duration-300 transform rounded-lg "
              to="/dashboard/attendance"
              onClick={handleSideBar}
            >
              <img src={attendanceIcon} alt="logo" className="w-6" />
              <span className="mx-2 text-sm font-medium text-black">
                Attendance
              </span>
            </NavLink>
            <hr className=" bg-gray-400" />

            <NavLink
              className="flex items-center px-9  text-gray-600 transition-colors duration-300 transform rounded-lg "
              to="/attendance/record"
              onClick={handleSideBar}
            >
              <img src={dailyWorkingIcon} alt="logo" className="w-6" />
              <span className="mx-2 mt-1 text-sm font-medium text-black">
                DAR
              </span>
            </NavLink>
            <hr className=" bg-gray-400" />

            <NavLink
              className="flex items-center px-9 text-gray-600 transition-colors duration-300 transform rounded-lg "
              to="/request"
              onClick={handleSideBar}
            >
              <img src={requestIcon} alt="logo" className="w-6" />
              <span className="mx-2 text-sm font-medium text-black">
                Request
              </span>
            </NavLink>
            <hr className="h-[0px] bg-gray-400" />
            <NavLink
              className="flex items-center px-9 text-gray-600 transition-colors duration-300 transform rounded-lg "
              to="/leads"
              onClick={handleSideBar}
            >
              <img src={leadsIcon} alt="logo" className="w-6" />
              <span className="mx-2 text-sm font-medium text-black">
                Leads
              </span>
            </NavLink>
            <hr className="h-[0px] bg-gray-400" />

            {/* <NavLink
              className="flex items-center px-9  text-gray-600 transition-colors duration-300 transform rounded-lg "
              to="/privacy"
              onClick={handleSideBar}
            >
              <img src={privacyIcon} alt="logo" className="w-6" />
              <span className="mx-2 text-sm font-medium text-black">
                Privacy Policy
              </span>
            </NavLink>
            <hr className="h-[0px] bg-gray-400" />

            <NavLink
              className="flex items-center px-9  text-gray-600 transition-colors duration-300 transform rounded-lg "
              to="/terms"
              onClick={handleSideBar}
            >
              <img src={termsIcon} alt="logo" className="w-6" />
              <span className="mx-2 text-sm font-medium text-black">
                Terms and Conditions
              </span>
            </NavLink>
            <hr className="h-[0px] bg-gray-400" /> */}

            <NavLink
              className="flex items-center px-9 text-gray-600 transition-colors duration-300 transform rounded-lg "
              to="/phone"
              onClick={handleLogOut}
            >
              <img src={logoutIcon} alt="logo" className="w-6" />
              <span className="mx-2 text-sm font-medium text-black">
                Logout
              </span>
            </NavLink>
          </nav>
        </div>
        <div className="absolute top-10 -right-8 z-20">
          <img
            src={crossIcon}
            alt="cross-logo"
            onClick={() => handleSideBar()}
          />
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
