import React from "react";
import sucessIcon from "../../assets/successTick.png";
import crossIcon from "../../assets/popupCross.png";

function Popup({ setIsModalOpen }) {
  return (
    <div>
      <div className="w-[300px] relative h-[100px] flex flex-col justify-center gap-2 items-center bg-[#fff] rounded-md  border-[1px] border-gray-300 shadow-6xl">
        <img src={sucessIcon} alt="logo" className="w-6" />
        <p>Successfully Submitted</p>

        <img
          src={crossIcon}
          alt="cross-logo"
          className="absolute right-2 top-2"
          onClick={() => setIsModalOpen(false)}
        />
      </div>
    </div>
  );
}

export default Popup;
