import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import superonlogo from "../../assets/superon.png";
import welcomeBg from "../../assets/welcomebg.png";
import config from "../Utils/config";

function WelcomePage() {
  const navigate = useNavigate();
  let timer = useRef(null);

  const handleUser = async () => {
    let phoneNum = localStorage.getItem("superonUserPhNum");
    console.log(phoneNum);

    if (phoneNum) {
      console.log("running...");
      let { data } = await axios.get(config.ipAddress + `/user/${phoneNum}`);
      console.log(data);
      if (data.data.status === "0") {
        navigate("/createprofile");
      }
      if (data.data.status === "1") {
        navigate("/pending");
      }
      if (data.data.status === "2") {
        navigate("/dashboard/target");
      }
    } else {
      timer.current = setTimeout(() => {
        navigate("/phone");
      }, 1000);
    }
  };
  useEffect(() => {
    handleUser();

    return () => {
      clearTimeout(timer.current);
    };
  });
  return (
    <div className="bg-gradient-to-b from-[#FF971A] to-[#F26401] h-screen md:w-2/4 relative">
      <div className="h-full flex flex-col justify-between items-center pt-60 md:pt-36">
        <img src={superonlogo} alt="superon-logo" className=" w-48 md:w-56" />

        <div className="text-center pb-10 text-white relative z-10 sm:hidden">
          <span className="text-[10px] opacity-50 font-extralight">
            Powered by
          </span>
          <h2 className="text-base">SUPERON</h2>
        </div>
        <img
          src={welcomeBg}
          alt="background"
          className="absolute bottom-0 left-0 -z-1 w-full invert sepia opacity-40 md:w-[100%] md:h-[28rem] "
        />
      </div>
    </div>
  );
}

export default WelcomePage;
