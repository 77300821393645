import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Dashboard/Navbar";
import axios from "axios";
import config from "../Utils/config";
import submitLogo from "../../assets/icon-submit.svg";
import loadingGif from "../../assets/loading2.gif";
import crossIcon from "../../assets/cross.svg";

import resetLogo from "../../assets/reset.svg";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import "./select.css";
import "../ViewAllAttendance/select.css";
import "../ViewAllAttendance/date.css";
import "./leads.css";
import ErrorPopup from "../popupModal/ErrorPopup";
import Select from "react-select";
import Chip from "./Chip";
import moment from "moment";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const selectCustomStyles = {
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "white",
    borderBottom: "0px solid #ccc",
    borderRadius: "0",
    marginTop: "8px",
  }),
  menuList: (provided, state) => ({
    maxHeight: "150px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
      borderRadius: "10px",
      transition: "all linear 0.5s",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#bababa",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    height: "30px",
    width: "100%",
    lineHeight: "1",
    backgroundColor: state.isFocused ? "rgba(0,0,0,0.1)" : "white",
    color: state.isFocused ? "#000" : "black",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: state.isFocused ? "rgba(0,0,0,0.1)" : "#f2f2f2",
      color: state.isFocused ? "#000" : "black",
    },
  }),
  control: (base, state) => ({
    ...base,
    borderRadius: "none",
    border: "1px solid rgb(209 213 219)",
    // borderBottom: "1px solid #ccc",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid rgb(209 213 219)",
    },
    width: "100%",
    fontSize: "14px",
    color: "#000",
    borderRadius: "4px",
    height: "36px",
  }),
};
function Leads() {
  const phoneNum = localStorage.getItem("superonUserPhNum");
  const navigate = useNavigate();

  const [currentPayload, setCurrentPayload] = useState({
    mobile: phoneNum,
    leadid: "",
    remark: "",
    status: "",
    dealername: "",
    ordervalue: "",
  });
  const [addRemarks, setAddRemarks] = useState("");
  const [status, setStatus] = useState("");
  const [currentYearName, setCurrentYearName] = useState(
    new Date().getFullYear()
  );

  const [leadsData, setLeadsData] = useState([]);
  const [statusFilterOptions, setStatusFilterOptions] = useState([
    { label: "All", value: "0" },
    { label: "Open", value: "1" },
    { label: "Closed", value: "2" },
  ]);
  const [statusOptions, setStatusOptions] = useState([
    { label: "Not Interested", value: "NI" },
    { label: "Order Received", value: "OR" },
  ]);
  const [statusFilterVal, setStatusFilterVal] = useState("");
  const [selectedDateStart, setSelectedDateStart] = useState(new Date(new Date() - 60 * 60 * 24 * 7 * 1000));
  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date());
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [message, setMessage] = useState("");

  const fetchLeads = async (status,startDate,endDate) => {
    console.log('fetchLeads dates',startDate,endDate)
    const params = (startDate && endDate) ? `?fromdate=${moment(startDate).format("YYYY-MM-DD")}&todate=${moment(endDate).format("YYYY-MM-DD")}` :""
    const payload = {
      mobile: phoneNum,
      fromdate: moment(startDate).format("YYYY-MM-DD"),
      todate: moment(endDate).format("YYYY-MM-DD")
    }
    try {
      const res = await axios.post(
        `${config.ipAddress}/leadbymobile`,payload
      );
      console.log("fetchLeads",statusFilterVal, res?.data?.data);
      if (res?.data?.data?.length) {
        if(status && status!=0){
          const filteredData =res?.data?.data.filter(item=>item?.extra5===status)
        setLeadsData(filteredData)
        }else{
        setLeadsData(res?.data?.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async () => {
    if (currentPayload?.status === "Order Received") {
      if (!currentPayload?.dealername) {
        setShowErrorModal(true);
        setMessage("Dealer Name is required");
        return;
      }
      if (!currentPayload?.ordervalue) {
        setShowErrorModal(true);
        setMessage("Order Value is required");
        return;
      }
    }
    if (!currentPayload?.remark) {
      setShowErrorModal(true);
      setMessage("Remarks is required");
      return;
    }
    try {
      const res = axios.post(`${config.ipAddress}/editlead`, currentPayload);
      // fetchLeads(statusFilterVal,selectedDateStart,selectedDateEnd);
      setAddRemarks("");
      setCurrentPayload({
        mobile: phoneNum,
        leadid: "",
        remark: "",
        status: "",
        dealername: "",
        ordervalue: "",
      });
      window.location.reload()
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchLeads(statusFilterVal,selectedDateStart,selectedDateEnd);
  }, [statusFilterVal]);

const handleDate = ()=>{
  const startDate = new Date(selectedDateStart).getDate();
  const startMonth = new Date(selectedDateStart).getMonth() + 1;
  const startYear = new Date(selectedDateStart).getFullYear();
  const endDate = new Date(selectedDateEnd).getDate();
  const endMonth = new Date(selectedDateEnd).getMonth() + 1;
  const endYear = new Date(selectedDateEnd).getFullYear();
  if (endYear > startYear) {
     setShowErrorModal(true);
      setMessage("Start date should be less than end date");
    return;
  }else if (startMonth > endMonth && startYear===endYear) {
     setShowErrorModal(true);
      setMessage("Start date should be less than end date");
    return;
  } else if (
    startDate > endDate &&
    startMonth === endMonth && startYear===endYear
  ) {
     setShowErrorModal(true);
      setMessage("Start date should be less than end date");
    return;
  } else {
    fetchLeads(statusFilterVal,selectedDateStart,selectedDateEnd);
  }
}

  return (
    <div className="h-[100dvh] overflow-auto bg-[#fafafa]">
      <div className="sticky top-0 left-0 z-[3]">
        <Navbar />
      </div>
      <section className="bg-white min-h-[89.2%] px-3 py-4 ">
        <main className="min-h-full ">
          <div className="py-4  shadow-6xl rounded-[10px] border-[1px] border-gray-300">
            <div className="flex justify-between items-center px-4">
              <div className="flex gap-2">
                <div className="bg-[#FF971A] w-[2px] rounded-full"></div>
                <div className="">
                  <h1 className="text-lg ">Leads</h1>
                </div>
              </div>
              <div style={{ width: "136px" }}>
                <Select
                  styles={selectCustomStyles}
                  onChange={(e) => {
                    console.log("option changed", e);
                    setStatusFilterVal(e?.value);
                  }}
                  options={statusFilterOptions}
                  placeholder={
                    <div style={{ fontSize: "12px" }}>Filter by status</div>
                  }
                />
              </div>
            </div>
            <div className="pt-4 px-4 bg-white">
              <div className="w-full flex justify-between gap-3">
                <Datepicker
                  popperClassName="some-custom-class1"
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: { behavior: ["bottom"] },
                    preventOverflow: { enabled: true },
                    hide: { enabled: false },
                  }}
                  selected={selectedDateStart}
                  onChange={(date) => setSelectedDateStart(date)}
                  dateFormat={"yyyy/MM/dd"}
                  placeholderText="From"
                  wrapperClassName="datePicker"
                ></Datepicker>

                <Datepicker
                  portalId="root-portal"
                  popperClassName="some-custom-class2"
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: { behavior: ["bottom"] },
                    preventOverflow: { enabled: true },
                    hide: { enabled: false },
                  }}
                  selected={selectedDateEnd}
                  onChange={(date) => setSelectedDateEnd(date)}
                  dateFormat={"yyyy/MM/dd"}
                  placeholderText="To"
                  wrapperClassName="datePicker"
                ></Datepicker>
              </div>
              <div className="flex justify-end gap-2 mt-4">
                <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
                  <img
                    src={resetLogo}
                    alt="submit-logo"
                    className="w-4 "
                    onClick={() => window.location.reload()}
                  />
                </div>
                <div className="border-[1px] flex justify-end  border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
                  <img
                    src={submitLogo}
                    alt="submit-logo"
                    className="w-[18px] mx-auto "
                    onClick={() => {
                      if(selectedDateStart && selectedDateEnd){
                      handleDate()
                    }else{
                      setShowErrorModal(true);
                      setMessage("Select both start and end date");
                    }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {leadsData.length
            ? leadsData.map((item, index) => {
                return (
                  <section
                    className={`bg-white mt-4 ${"py-3"} rounded-[10px] shadow-6xl border-[1px] border-gray-200 `}
                  >
                    <div className="flex justify-between items-center px-4">
                      <div className="flex gap-2 py-2 pt-2">
                        <div className="bg-[#FF971A] w-[2px] rounded-full"></div>
                        <h1 className="text-lg ">Lead {index + 1}</h1>
                      </div>
                      <div style={{display:"flex"}}>
                      {addRemarks !== item?.id && !item?.extra1 ? 
                      <button
                        className="lead-remark-btn rounded-full  text-blue-500"
                        // style={{ fontSize: "0.875rem" }}
                        onClick={() => setAddRemarks(item?.id)}
                      >
                          Add Remarks
                      </button> : null}
                      <Chip text={!item?.extra1 ? "Open" : "Closed"} textColor={!item?.extra1 ? "#005ba9" : "#a12c28"} bg={!item?.extra1 ? "#eef8fc" :"#fdebed"}/>
                      
                      </div>
                    </div>

                    <React.Fragment key={index}>
                      <div className="leads-info-item px-4">
                        <label>Name:</label>
                        <span>{item?.name ? item?.name : "-"}</span>
                      </div>
                      <div className="leads-info-item px-4">
                        <label>State:</label>
                        <span>{item?.state ? item?.state : "-"}</span>
                      </div>
                      <div className="leads-info-item px-4">
                        <label>City:</label>
                        <span>{item?.city ? item?.city : "-"}</span>
                      </div>
                      <div className="leads-info-item px-4">
                        <label>Mobile:</label>
                        <span>{item?.mobile ? item?.mobile : "-"}</span>
                      </div>
                      <div className="leads-info-item px-4">
                        <label>Requirement:</label>
                        <span>
                          {item?.extra4
                            ? item?.extra4
                            : "No requirement available"}
                        </span>
                      </div>
                      {addRemarks !== item?.id && !item?.extra1 ? null :
                      <div className="px-4 py-2">
                        <div className="seperator-line"></div>
                      </div>}
                      {item?.extra1 ? (
                        <div className="leads-info-wrapper">
                          <div className="leads-info-item">
                            <label>Status:</label>
                            <span>{item?.extra1}</span>
                          </div>
                          {item?.extra2 ? (
                            <div className="leads-info-item">
                              <label>Dealer Name:</label>
                              <span>{item?.extra2}</span>
                            </div>
                          ) : null}
                          {item?.extra3 ? (
                            <div className="leads-info-item">
                              <label>Order Value:</label>
                              <span>{item?.extra3}</span>
                            </div>
                          ) : null}
                          <div className="leads-info-item">
                            <label>Remark:</label>
                            <span>{item?.remark}</span>
                          </div>
                        </div>
                      ) : null}
                      {addRemarks === item?.id && !item?.extra1 ? (
                        <div>
                          <div
                            style={{ padding: "0px 16px", margin: "8px 0px" }}
                          >
                            <Select
                              styles={selectCustomStyles}
                              onChange={(e) => {
                                console.log("option changed", e);
                                setStatus(e?.label);
                                setCurrentPayload({
                                  mobile: phoneNum,
                                  leadid: item?.id,
                                  remark: "",
                                  status: e?.label,
                                  dealername: "",
                                  ordervalue: "",
                                });
                              }}
                              options={statusOptions}
                              className="mt-2"
                              placeholder={
                                <div style={{ fontSize: "12px" }}>
                                  Select status
                                </div>
                              }
                            />
                          </div>
                          <div style={{ padding: "0px 16px" }}>
                            {currentPayload?.status === "Order Received" ? (
                              <input
                                type="text"
                                required
                                placeholder="Dealer Name"
                                className="my-2 w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light"
                                value={currentPayload?.dealername}
                                onChange={(e) =>
                                  setCurrentPayload({
                                    ...currentPayload,
                                    ["dealername"]: e?.target?.value,
                                  })
                                }
                              />
                            ) : null}
                            {currentPayload?.status === "Order Received" ? (
                              <input
                                type="text"
                                required
                                placeholder="Order Value"
                                className="my-2 w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light"
                                value={currentPayload?.ordervalue}
                                onChange={(e) =>
                                  setCurrentPayload({
                                    ...currentPayload,
                                    ["ordervalue"]: e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    ),
                                  })
                                }
                              />
                            ) : null}
                            {currentPayload?.status === "Order Received" ||
                            currentPayload?.status === "Not Interested" ? (
                              <textarea
                                type="text"
                                className="w-full p-1  mt-3 border-[1px] text-sm focus:outline-none focus:border-orange-light border-gray-300 rounded-[4px] "
                                cols="30"
                                rows="2"
                                placeholder="Remark"
                                value={currentPayload?.remark}
                                onChange={(e) =>
                                  setCurrentPayload({
                                    ...currentPayload,
                                    ["remark"]: e?.target?.value,
                                  })
                                }
                              />
                            ) : null}
                          </div>
                          <div className="flex justify-end gap-2 mt-4 px-4">
                            <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
                              {addRemarks === item?.id ? (
                                <img
                                  src={crossIcon}
                                  alt="cancel-logo"
                                  className="w-4 cursor-pointer"
                                  onClick={() => {
                                    setAddRemarks("");
                                    setCurrentPayload({
                                      mobile: phoneNum,
                                      leadid: "",
                                      remark: "",
                                      status: "",
                                      dealername: "",
                                      ordervalue: "",
                                    });
                                  }}
                                />
                              ) : null}
                            </div>
                            {currentPayload?.status === "Order Received" ||
                            currentPayload?.status === "Not Interested" ? (
                              <div className="border-[1px] flex justify-end  border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
                                <img
                                  src={submitLogo}
                                  alt="submit-logo"
                                  className="w-[18px] mx-auto cursor-pointer"
                                  onClick={() => handleSubmit()}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </React.Fragment>
                  </section>
                );
              })
            : ""}
        </main>
      </section>

      {showErrorModal ? (
        <div className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-150px]">
          <ErrorPopup setShowErrorModal={setShowErrorModal} message={message} />
        </div>
      ) : (
        ""
      )}
      {showErrorModal ? (
        <div
          onClick={() => setShowErrorModal(false)}
          className="w-full h-[100%] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      ) : (
        ""
      )}

      {showLoading && (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-50px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <img src={loadingGif} className="w-[100px]" alt="loading-logo" />
        </div>
      )}

      {showLoading && (
        <div
          // onClick={() => setIsModalOpen(false)}
          className="w-full h-[100%] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      )}
    </div>
  );
}

export default Leads;
